// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as DateFns from "../utils/DateFns.bs.js";
import * as ListUtils from "../utils/ListUtils.bs.js";
import * as ArrayUtils from "../utils/ArrayUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as GraphqlQuery from "../utils/GraphqlQuery.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var UnexpectedBlockType = /* @__PURE__ */Caml_exceptions.create("ContentBlock.UnexpectedBlockType");

var UnexpectedRequestSource = /* @__PURE__ */Caml_exceptions.create("ContentBlock.UnexpectedRequestSource");

function widthToClass(width) {
  switch (width) {
    case /* Auto */0 :
        return "w-auto";
    case /* Full */1 :
        return "w-full";
    case /* FourFifths */2 :
        return "w-4/5";
    case /* ThreeFifths */3 :
        return "w-3/5";
    case /* TwoFifths */4 :
        return "w-2/5";
    
  }
}

function decodeMarkdownContent(json) {
  return Json_decode.field("markdown", Json_decode.string, json);
}

function decodeFileContent(json) {
  return Json_decode.field("title", Json_decode.string, json);
}

function decodeImageContent(json) {
  var widthString = Json_decode.field("width", Json_decode.string, json);
  var width;
  switch (widthString) {
    case "Auto" :
        width = /* Auto */0;
        break;
    case "FourFifths" :
        width = /* FourFifths */2;
        break;
    case "Full" :
        width = /* Full */1;
        break;
    case "ThreeFifths" :
        width = /* ThreeFifths */3;
        break;
    case "TwoFifths" :
        width = /* TwoFifths */4;
        break;
    default:
      Rollbar.error("Encountered unexpected width for image content block: " + widthString);
      width = /* Auto */0;
  }
  return [
          Json_decode.field("caption", Json_decode.string, json),
          width
        ];
}

function decodeEmbedContent(json) {
  var requestSourceString = Json_decode.field("requestSource", Json_decode.string, json);
  var requestSource;
  switch (requestSourceString) {
    case "User" :
        requestSource = "User";
        break;
    case "VimeoUpload" :
        requestSource = "VimeoUpload";
        break;
    default:
      Rollbar.error("Unexpected requestSource encountered in ContentBlock.re: " + requestSourceString);
      throw {
            RE_EXN_ID: UnexpectedRequestSource,
            _1: requestSourceString,
            Error: new Error()
          };
  }
  return [
          Json_decode.field("url", Json_decode.string, json),
          Json_decode.optional((function (param) {
                  return Json_decode.field("embedCode", Json_decode.string, param);
                }), json),
          requestSource,
          Json_decode.optional((function (param) {
                  return Json_decode.field("lastResolvedAt", DateFns.decodeISO, param);
                }), json)
        ];
}

function decode(json) {
  var unknownBlockType = Json_decode.field("blockType", Json_decode.string, json);
  var blockType;
  switch (unknownBlockType) {
    case "audio" :
        var title = Json_decode.field("content", decodeFileContent, json);
        var url = Json_decode.field("fileUrl", Json_decode.string, json);
        var filename = Json_decode.field("filename", Json_decode.string, json);
        blockType = {
          TAG: /* Audio */4,
          _0: url,
          _1: title,
          _2: filename
        };
        break;
    case "embed" :
        var match = Json_decode.field("content", decodeEmbedContent, json);
        blockType = {
          TAG: /* Embed */3,
          _0: match[0],
          _1: match[1],
          _2: match[2],
          _3: match[3]
        };
        break;
    case "file" :
        var title$1 = Json_decode.field("content", decodeFileContent, json);
        var url$1 = Json_decode.field("fileUrl", Json_decode.string, json);
        var filename$1 = Json_decode.field("filename", Json_decode.string, json);
        blockType = {
          TAG: /* File */1,
          _0: url$1,
          _1: title$1,
          _2: filename$1
        };
        break;
    case "image" :
        var match$1 = Json_decode.field("content", decodeImageContent, json);
        var url$2 = Json_decode.field("fileUrl", Json_decode.string, json);
        blockType = {
          TAG: /* Image */2,
          _0: url$2,
          _1: match$1[0],
          _2: match$1[1]
        };
        break;
    case "lesson_embed" :
        var match$2 = Json_decode.field("content", decodeEmbedContent, json);
        blockType = {
          TAG: /* Embed */3,
          _0: match$2[0],
          _1: match$2[1],
          _2: match$2[2],
          _3: match$2[3]
        };
        break;
    case "markdown" :
        var markdown = Json_decode.field("content", decodeMarkdownContent, json);
        blockType = {
          TAG: /* Markdown */0,
          _0: markdown
        };
        break;
    default:
      throw {
            RE_EXN_ID: UnexpectedBlockType,
            _1: unknownBlockType,
            Error: new Error()
          };
  }
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          blockType: blockType,
          sortIndex: Json_decode.field("sortIndex", Json_decode.$$int, json)
        };
}

function sort(blocks) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.sortIndex - y.sortIndex | 0;
              }), blocks);
}

function id(t) {
  return t.id;
}

function blockType(t) {
  return t.blockType;
}

function sortIndex(t) {
  return t.sortIndex;
}

function makeMarkdownBlock(markdown) {
  return {
          TAG: /* Markdown */0,
          _0: markdown
        };
}

function makeImageBlock(fileUrl, caption, width) {
  return {
          TAG: /* Image */2,
          _0: fileUrl,
          _1: caption,
          _2: width
        };
}

function makeFileBlock(fileUrl, title, fileName) {
  return {
          TAG: /* File */1,
          _0: fileUrl,
          _1: title,
          _2: fileName
        };
}

function makeEmbedBlock(url, embedCode, requestSource, lastResolvedAt) {
  return {
          TAG: /* Embed */3,
          _0: url,
          _1: embedCode,
          _2: requestSource,
          _3: lastResolvedAt
        };
}

function makeAudioBlock(fileUrl, title, fileName) {
  return {
          TAG: /* Audio */4,
          _0: fileUrl,
          _1: title,
          _2: fileName
        };
}

function make(id, blockType, sortIndex) {
  return {
          id: id,
          blockType: blockType,
          sortIndex: sortIndex
        };
}

function makeFromJs(js) {
  var id = js.id;
  var sortIndex = js.sortIndex;
  var match = js.content;
  var variant = match.NAME;
  var blockType;
  if (variant === "FileBlock") {
    var content = match.VAL;
    blockType = {
      TAG: /* File */1,
      _0: content.url,
      _1: content.title,
      _2: content.filename
    };
  } else if (variant === "MarkdownBlock") {
    blockType = {
      TAG: /* Markdown */0,
      _0: match.VAL.markdown
    };
  } else if (variant === "EmbedBlock") {
    var content$1 = match.VAL;
    blockType = {
      TAG: /* Embed */3,
      _0: content$1.url,
      _1: content$1.embedCode,
      _2: content$1.requestSource,
      _3: Belt_Option.map(content$1.lastResolvedAt, DateFns.parseISO)
    };
  } else if (variant === "ImageBlock") {
    var content$2 = match.VAL;
    var match$1 = content$2.width;
    blockType = {
      TAG: /* Image */2,
      _0: content$2.url,
      _1: content$2.caption,
      _2: match$1 === "ThreeFifths" ? /* ThreeFifths */3 : (
          match$1 === "TwoFifths" ? /* TwoFifths */4 : (
              match$1 === "Auto" ? /* Auto */0 : (
                  match$1 === "Full" ? /* Full */1 : /* FourFifths */2
                )
            )
        )
    };
  } else {
    var content$3 = match.VAL;
    blockType = {
      TAG: /* Audio */4,
      _0: content$3.url,
      _1: content$3.title,
      _2: content$3.filename
    };
  }
  return {
          id: id,
          blockType: blockType,
          sortIndex: sortIndex
        };
}

function blockTypeAsString(blockType) {
  switch (blockType.TAG | 0) {
    case /* Markdown */0 :
        return "markdown";
    case /* File */1 :
        return "file";
    case /* Image */2 :
        return "image";
    case /* Embed */3 :
        return "lesson_embed";
    case /* Audio */4 :
        return "audio";
    
  }
}

function incrementSortIndex(t) {
  return {
          id: t.id,
          blockType: t.blockType,
          sortIndex: t.sortIndex + 1 | 0
        };
}

function reindex(ts) {
  return List.mapi((function (sortIndex, t) {
                return {
                        id: t.id,
                        blockType: t.blockType,
                        sortIndex: sortIndex
                      };
              }), ts);
}

function moveUp(t, ts) {
  return $$Array.of_list(reindex(ListUtils.swapUp(t, $$Array.to_list(sort(ts)))));
}

function moveDown(t, ts) {
  return $$Array.of_list(reindex(ListUtils.swapDown(t, $$Array.to_list(sort(ts)))));
}

function updateFile(title, t) {
  var match = t.blockType;
  if (match.TAG === /* File */1) {
    return {
            id: t.id,
            blockType: {
              TAG: /* File */1,
              _0: match._0,
              _1: title,
              _2: match._2
            },
            sortIndex: t.sortIndex
          };
  } else {
    return t;
  }
}

function updateImageCaption(t, caption) {
  var match = t.blockType;
  if (match.TAG === /* Image */2) {
    return {
            id: t.id,
            blockType: {
              TAG: /* Image */2,
              _0: match._0,
              _1: caption,
              _2: match._2
            },
            sortIndex: t.sortIndex
          };
  } else {
    return t;
  }
}

function updateImageWidth(t, width) {
  var match = t.blockType;
  if (match.TAG === /* Image */2) {
    return {
            id: t.id,
            blockType: {
              TAG: /* Image */2,
              _0: match._0,
              _1: match._1,
              _2: width
            },
            sortIndex: t.sortIndex
          };
  } else {
    return t;
  }
}

function updateMarkdown(markdown, t) {
  var match = t.blockType;
  if (match.TAG === /* Markdown */0) {
    return {
            id: t.id,
            blockType: {
              TAG: /* Markdown */0,
              _0: markdown
            },
            sortIndex: t.sortIndex
          };
  } else {
    return t;
  }
}

var Raw = {};

var query = "query ContentBlocksWithVersionsQuery($targetId: ID!, $targetVersionId: ID)  {\ncontentBlocks(targetId: $targetId, targetVersionId: $targetVersionId)  {\nid  \nblockType  \nsortIndex  \ncontent  {\n__typename\n...on ImageBlock   {\ncaption  \nurl  \nfilename  \nwidth  \n}\n\n...on FileBlock   {\ntitle  \nurl  \nfilename  \n}\n\n...on AudioBlock   {\ntitle  \nurl  \nfilename  \n}\n\n...on MarkdownBlock   {\nmarkdown  \n}\n\n...on EmbedBlock   {\nurl  \nembedCode  \nrequestSource  \nlastResolvedAt  \n}\n\n}\n\n}\n\ntargetVersions(targetId: $targetId)  {\nid  \ncreatedAt  \nupdatedAt  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.contentBlocks;
  var value$2 = value.targetVersions;
  return {
          contentBlocks: value$1.map(function (value) {
                var value$1 = value.content;
                var typename = value$1["__typename"];
                var tmp;
                switch (typename) {
                  case "AudioBlock" :
                      tmp = {
                        NAME: "AudioBlock",
                        VAL: {
                          title: value$1.title,
                          url: value$1.url,
                          filename: value$1.filename
                        }
                      };
                      break;
                  case "EmbedBlock" :
                      var value$2 = value$1.embedCode;
                      var value$3 = value$1.requestSource;
                      var tmp$1;
                      switch (value$3) {
                        case "User" :
                            tmp$1 = "User";
                            break;
                        case "VimeoUpload" :
                            tmp$1 = "VimeoUpload";
                            break;
                        default:
                          tmp$1 = {
                            NAME: "FutureAddedValue",
                            VAL: value$3
                          };
                      }
                      var value$4 = value$1.lastResolvedAt;
                      tmp = {
                        NAME: "EmbedBlock",
                        VAL: {
                          url: value$1.url,
                          embedCode: !(value$2 == null) ? value$2 : undefined,
                          requestSource: tmp$1,
                          lastResolvedAt: !(value$4 == null) ? value$4 : undefined
                        }
                      };
                      break;
                  case "FileBlock" :
                      tmp = {
                        NAME: "FileBlock",
                        VAL: {
                          title: value$1.title,
                          url: value$1.url,
                          filename: value$1.filename
                        }
                      };
                      break;
                  case "ImageBlock" :
                      var value$5 = value$1.width;
                      var tmp$2;
                      switch (value$5) {
                        case "Auto" :
                            tmp$2 = "Auto";
                            break;
                        case "FourFifths" :
                            tmp$2 = "FourFifths";
                            break;
                        case "Full" :
                            tmp$2 = "Full";
                            break;
                        case "ThreeFifths" :
                            tmp$2 = "ThreeFifths";
                            break;
                        case "TwoFifths" :
                            tmp$2 = "TwoFifths";
                            break;
                        default:
                          tmp$2 = {
                            NAME: "FutureAddedValue",
                            VAL: value$5
                          };
                      }
                      tmp = {
                        NAME: "ImageBlock",
                        VAL: {
                          caption: value$1.caption,
                          url: value$1.url,
                          filename: value$1.filename,
                          width: tmp$2
                        }
                      };
                      break;
                  case "MarkdownBlock" :
                      tmp = {
                        NAME: "MarkdownBlock",
                        VAL: {
                          markdown: value$1.markdown
                        }
                      };
                      break;
                  default:
                    tmp = {
                      NAME: "FutureAddedValue",
                      VAL: value$1
                    };
                }
                return {
                        id: value.id,
                        blockType: value.blockType,
                        sortIndex: value.sortIndex,
                        content: tmp
                      };
              }),
          targetVersions: value$2.map(function (value) {
                return {
                        id: value.id,
                        createdAt: value.createdAt,
                        updatedAt: value.updatedAt
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.targetVersions;
  var targetVersions = value$1.map(function (value) {
        var value$1 = value.updatedAt;
        var value$2 = value.createdAt;
        var value$3 = value.id;
        return {
                id: value$3,
                createdAt: value$2,
                updatedAt: value$1
              };
      });
  var value$2 = value.contentBlocks;
  var contentBlocks = value$2.map(function (value) {
        var value$1 = value.content;
        var variant = value$1.NAME;
        var content;
        if (variant === "FileBlock") {
          var value$2 = value$1.VAL;
          var value$3 = value$2.filename;
          var value$4 = value$2.url;
          var value$5 = value$2.title;
          content = {
            __typename: "FileBlock",
            title: value$5,
            url: value$4,
            filename: value$3
          };
        } else if (variant === "MarkdownBlock") {
          var value$6 = value$1.VAL.markdown;
          content = {
            __typename: "MarkdownBlock",
            markdown: value$6
          };
        } else if (variant === "FutureAddedValue") {
          content = value$1.VAL;
        } else if (variant === "EmbedBlock") {
          var value$7 = value$1.VAL;
          var value$8 = value$7.lastResolvedAt;
          var lastResolvedAt = value$8 !== undefined ? value$8 : null;
          var value$9 = value$7.requestSource;
          var requestSource = typeof value$9 === "object" ? value$9.VAL : (
              value$9 === "User" ? "User" : "VimeoUpload"
            );
          var value$10 = value$7.embedCode;
          var embedCode = value$10 !== undefined ? value$10 : null;
          var value$11 = value$7.url;
          content = {
            __typename: "EmbedBlock",
            url: value$11,
            embedCode: embedCode,
            requestSource: requestSource,
            lastResolvedAt: lastResolvedAt
          };
        } else if (variant === "ImageBlock") {
          var value$12 = value$1.VAL;
          var value$13 = value$12.width;
          var width = typeof value$13 === "object" ? value$13.VAL : (
              value$13 === "ThreeFifths" ? "ThreeFifths" : (
                  value$13 === "TwoFifths" ? "TwoFifths" : (
                      value$13 === "Auto" ? "Auto" : (
                          value$13 === "Full" ? "Full" : "FourFifths"
                        )
                    )
                )
            );
          var value$14 = value$12.filename;
          var value$15 = value$12.url;
          var value$16 = value$12.caption;
          content = {
            __typename: "ImageBlock",
            caption: value$16,
            url: value$15,
            filename: value$14,
            width: width
          };
        } else {
          var value$17 = value$1.VAL;
          var value$18 = value$17.filename;
          var value$19 = value$17.url;
          var value$20 = value$17.title;
          content = {
            __typename: "AudioBlock",
            title: value$20,
            url: value$19,
            filename: value$18
          };
        }
        var value$21 = value.sortIndex;
        var value$22 = value.blockType;
        var value$23 = value.id;
        return {
                id: value$23,
                blockType: value$22,
                sortIndex: value$21,
                content: content
              };
      });
  return {
          contentBlocks: contentBlocks,
          targetVersions: targetVersions
        };
}

function serializeVariables(inp) {
  var a = inp.targetVersionId;
  return {
          targetId: inp.targetId,
          targetVersionId: a !== undefined ? a : undefined
        };
}

function makeVariables(targetId, targetVersionId, param) {
  return {
          targetId: targetId,
          targetVersionId: targetVersionId
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var Query_Graphql_error = include.Graphql_error;

var Query_decodeNotification = include.decodeNotification;

var Query_decodeObject = include.decodeObject;

var Query_decodeNotifications = include.decodeNotifications;

var Query_decodeErrors = include.decodeErrors;

var Query_flashNotifications = include.flashNotifications;

var Query_sendQuery = include.sendQuery;

var Query_query = include.query;

var Query_fetch = include.$$fetch;

var Query_make = include.make;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: Query_Graphql_error,
  decodeNotification: Query_decodeNotification,
  decodeObject: Query_decodeObject,
  decodeNotifications: Query_decodeNotifications,
  decodeErrors: Query_decodeErrors,
  flashNotifications: Query_flashNotifications,
  sendQuery: Query_sendQuery,
  query: Query_query,
  $$fetch: Query_fetch,
  make: Query_make
};

var Raw$1 = {};

function parse$1(value) {
  var value$1 = value.content;
  var typename = value$1["__typename"];
  var tmp;
  switch (typename) {
    case "AudioBlock" :
        tmp = {
          NAME: "AudioBlock",
          VAL: {
            title: value$1.title,
            url: value$1.url,
            filename: value$1.filename
          }
        };
        break;
    case "EmbedBlock" :
        var value$2 = value$1.embedCode;
        var value$3 = value$1.requestSource;
        var tmp$1;
        switch (value$3) {
          case "User" :
              tmp$1 = "User";
              break;
          case "VimeoUpload" :
              tmp$1 = "VimeoUpload";
              break;
          default:
            tmp$1 = {
              NAME: "FutureAddedValue",
              VAL: value$3
            };
        }
        var value$4 = value$1.lastResolvedAt;
        tmp = {
          NAME: "EmbedBlock",
          VAL: {
            url: value$1.url,
            embedCode: !(value$2 == null) ? value$2 : undefined,
            requestSource: tmp$1,
            lastResolvedAt: !(value$4 == null) ? value$4 : undefined
          }
        };
        break;
    case "FileBlock" :
        tmp = {
          NAME: "FileBlock",
          VAL: {
            title: value$1.title,
            url: value$1.url,
            filename: value$1.filename
          }
        };
        break;
    case "ImageBlock" :
        var value$5 = value$1.width;
        var tmp$2;
        switch (value$5) {
          case "Auto" :
              tmp$2 = "Auto";
              break;
          case "FourFifths" :
              tmp$2 = "FourFifths";
              break;
          case "Full" :
              tmp$2 = "Full";
              break;
          case "ThreeFifths" :
              tmp$2 = "ThreeFifths";
              break;
          case "TwoFifths" :
              tmp$2 = "TwoFifths";
              break;
          default:
            tmp$2 = {
              NAME: "FutureAddedValue",
              VAL: value$5
            };
        }
        tmp = {
          NAME: "ImageBlock",
          VAL: {
            caption: value$1.caption,
            url: value$1.url,
            filename: value$1.filename,
            width: tmp$2
          }
        };
        break;
    case "MarkdownBlock" :
        tmp = {
          NAME: "MarkdownBlock",
          VAL: {
            markdown: value$1.markdown
          }
        };
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  return {
          id: value.id,
          blockType: value.blockType,
          sortIndex: value.sortIndex,
          content: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.content;
  var variant = value$1.NAME;
  var content;
  if (variant === "FileBlock") {
    var value$2 = value$1.VAL;
    var value$3 = value$2.filename;
    var value$4 = value$2.url;
    var value$5 = value$2.title;
    content = {
      __typename: "FileBlock",
      title: value$5,
      url: value$4,
      filename: value$3
    };
  } else if (variant === "MarkdownBlock") {
    var value$6 = value$1.VAL.markdown;
    content = {
      __typename: "MarkdownBlock",
      markdown: value$6
    };
  } else if (variant === "FutureAddedValue") {
    content = value$1.VAL;
  } else if (variant === "EmbedBlock") {
    var value$7 = value$1.VAL;
    var value$8 = value$7.lastResolvedAt;
    var lastResolvedAt = value$8 !== undefined ? value$8 : null;
    var value$9 = value$7.requestSource;
    var requestSource = typeof value$9 === "object" ? value$9.VAL : (
        value$9 === "User" ? "User" : "VimeoUpload"
      );
    var value$10 = value$7.embedCode;
    var embedCode = value$10 !== undefined ? value$10 : null;
    var value$11 = value$7.url;
    content = {
      __typename: "EmbedBlock",
      url: value$11,
      embedCode: embedCode,
      requestSource: requestSource,
      lastResolvedAt: lastResolvedAt
    };
  } else if (variant === "ImageBlock") {
    var value$12 = value$1.VAL;
    var value$13 = value$12.width;
    var width = typeof value$13 === "object" ? value$13.VAL : (
        value$13 === "ThreeFifths" ? "ThreeFifths" : (
            value$13 === "TwoFifths" ? "TwoFifths" : (
                value$13 === "Auto" ? "Auto" : (
                    value$13 === "Full" ? "Full" : "FourFifths"
                  )
              )
          )
      );
    var value$14 = value$12.filename;
    var value$15 = value$12.url;
    var value$16 = value$12.caption;
    content = {
      __typename: "ImageBlock",
      caption: value$16,
      url: value$15,
      filename: value$14,
      width: width
    };
  } else {
    var value$17 = value$1.VAL;
    var value$18 = value$17.filename;
    var value$19 = value$17.url;
    var value$20 = value$17.title;
    content = {
      __typename: "AudioBlock",
      title: value$20,
      url: value$19,
      filename: value$18
    };
  }
  var value$21 = value.sortIndex;
  var value$22 = value.blockType;
  var value$23 = value.id;
  return {
          id: value$23,
          blockType: value$22,
          sortIndex: value$21,
          content: content
        };
}

function verifyArgsAndParse(_ContentBlockFragment, value) {
  return parse$1(value);
}

function verifyName(param) {
  
}

var Fragment = {
  Raw: Raw$1,
  query: "fragment ContentBlockFragment on ContentBlock   {\nid  \nblockType  \nsortIndex  \ncontent  {\n__typename\n...on ImageBlock   {\ncaption  \nurl  \nfilename  \nwidth  \n}\n\n...on FileBlock   {\ntitle  \nurl  \nfilename  \n}\n\n...on AudioBlock   {\ntitle  \nurl  \nfilename  \n}\n\n...on MarkdownBlock   {\nmarkdown  \n}\n\n...on EmbedBlock   {\nurl  \nembedCode  \nrequestSource  \nlastResolvedAt  \n}\n\n}\n\n}\n",
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

export {
  UnexpectedBlockType ,
  UnexpectedRequestSource ,
  widthToClass ,
  decodeMarkdownContent ,
  decodeFileContent ,
  decodeImageContent ,
  decodeEmbedContent ,
  decode ,
  sort ,
  id ,
  blockType ,
  sortIndex ,
  makeMarkdownBlock ,
  makeImageBlock ,
  makeFileBlock ,
  makeEmbedBlock ,
  makeAudioBlock ,
  make ,
  makeFromJs ,
  blockTypeAsString ,
  incrementSortIndex ,
  reindex ,
  moveUp ,
  moveDown ,
  updateFile ,
  updateImageCaption ,
  updateImageWidth ,
  updateMarkdown ,
  Query ,
  Fragment ,
  
}
/* include Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ArrayUtils from "../utils/ArrayUtils.bs.js";
import * as GradeLabel from "./GradeLabel.bs.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decode(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.string, json),
          maxGrade: Json_decode.field("maxGrade", Json_decode.$$int, json),
          passGrade: Json_decode.field("passGrade", Json_decode.$$int, json),
          gradesAndLabels: Json_decode.field("gradeLabels", (function (param) {
                  return Json_decode.array(GradeLabel.decode, param);
                }), json)
        };
}

function id(t) {
  return t.id;
}

function maxGrade(t) {
  return t.maxGrade;
}

function sort(evaluationCriteria) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.name.localeCompare(y.name) | 0;
              }), evaluationCriteria);
}

function name(t) {
  return t.name;
}

function gradesAndLabels(t) {
  return t.gradesAndLabels;
}

function passGrade(t) {
  return t.passGrade;
}

function makeFromJs(evaluationCriterion) {
  return {
          name: evaluationCriterion.name,
          id: evaluationCriterion.id,
          maxGrade: evaluationCriterion.maxGrade,
          passGrade: evaluationCriterion.passGrade,
          gradesAndLabels: evaluationCriterion.gradeLabels.map(GradeLabel.makeFromJs)
        };
}

function make(id, name, maxGrade, passGrade, gradesAndLabels) {
  return {
          name: name,
          id: id,
          maxGrade: maxGrade,
          passGrade: passGrade,
          gradesAndLabels: gradesAndLabels
        };
}

export {
  decode ,
  id ,
  maxGrade ,
  sort ,
  name ,
  gradesAndLabels ,
  passGrade ,
  makeFromJs ,
  make ,
  
}
/* ArrayUtils Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as $$String from "../../../../../../node_modules/rescript/lib/es6/string.js";
import * as ArrayUtils from "../../../../shared/utils/ArrayUtils.bs.js";
import * as Json_decode from "../../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "../../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";

var partial_arg = "components.TargetChecklistItem";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function title(t) {
  return t.title;
}

function kind(t) {
  return t.kind;
}

function optional(t) {
  return t.optional;
}

function actionStringForKind(kind) {
  if (typeof kind !== "number") {
    return t(undefined, undefined, "action_string_choose_from_list");
  }
  switch (kind) {
    case /* Files */0 :
        return t(undefined, undefined, "action_string_upload_files");
    case /* Link */1 :
        return t(undefined, undefined, "action_string_attach_link");
    case /* ShortText */2 :
        return t(undefined, undefined, "action_string_write_short_text");
    case /* LongText */3 :
        return t(undefined, undefined, "action_string_write_long_text");
    case /* AudioRecord */4 :
        return t(undefined, undefined, "action_string_record_audio");
    
  }
}

function kindAsString(kind) {
  if (typeof kind !== "number") {
    return "multiChoice";
  }
  switch (kind) {
    case /* Files */0 :
        return "files";
    case /* Link */1 :
        return "link";
    case /* ShortText */2 :
        return "shortText";
    case /* LongText */3 :
        return "longText";
    case /* AudioRecord */4 :
        return "audio";
    
  }
}

function make(title, kind, optional) {
  return {
          title: title,
          kind: kind,
          optional: optional
        };
}

function updateTitle(title, t) {
  return {
          title: title,
          kind: t.kind,
          optional: t.optional
        };
}

function updateKind(kind, t) {
  return {
          title: t.title,
          kind: kind,
          optional: t.optional
        };
}

function updateOptional(optional, t) {
  return {
          title: t.title,
          kind: t.kind,
          optional: optional
        };
}

function removeItem(index, array) {
  return array.filter(function (_item, i) {
              return i !== index;
            });
}

var moveUp = ArrayUtils.swapUp;

var moveDown = ArrayUtils.swapDown;

function copy(index, array) {
  return ArrayUtils.flattenV2(array.map(function (item, i) {
                  if (i === index) {
                    return [
                            item,
                            item
                          ];
                  } else {
                    return [item];
                  }
                }));
}

function removeMultichoiceOption(choiceIndex, t) {
  var choices = t.kind;
  if (typeof choices === "number") {
    return t;
  }
  var updatedChoices = ArrayUtils.flattenV2($$Array.mapi((function (i, choice) {
              if (i === choiceIndex) {
                return [];
              } else {
                return [choice];
              }
            }), choices._0));
  return updateKind(/* MultiChoice */{
              _0: updatedChoices
            }, t);
}

function addMultichoiceOption(t) {
  var choices = t.kind;
  if (typeof choices === "number") {
    return t;
  }
  var updatedChoices = choices._0.concat([""]);
  return updateKind(/* MultiChoice */{
              _0: updatedChoices
            }, t);
}

function updateMultichoiceOption(choiceIndex, newOption, t) {
  var choices = t.kind;
  if (typeof choices === "number") {
    return t;
  }
  var updatedChoices = choices._0.map(function (choice, i) {
        if (i === choiceIndex) {
          return newOption;
        } else {
          return choice;
        }
      });
  return updateKind(/* MultiChoice */{
              _0: updatedChoices
            }, t);
}

function isFilesKind(t) {
  var _choices = t.kind;
  return _choices === 0;
}

function isValidChecklistItem(t) {
  var titleValid = t.title.trim().length >= 1;
  var choices = t.kind;
  if (typeof choices === "number" || ArrayUtils.isEmpty(choices._0.filter(function (choice) {
              return $$String.trim(choice) === "";
            }))) {
    return titleValid;
  } else {
    return false;
  }
}

function decodeMetadata(kind, json) {
  return Json_decode.field("choices", (function (param) {
                return Json_decode.array(Json_decode.string, param);
              }), json);
}

function decode(json) {
  var otherKind = Json_decode.field("kind", Json_decode.string, json);
  var tmp;
  switch (otherKind) {
    case "audio" :
        tmp = /* AudioRecord */4;
        break;
    case "files" :
        tmp = /* Files */0;
        break;
    case "link" :
        tmp = /* Link */1;
        break;
    case "longText" :
        tmp = /* LongText */3;
        break;
    case "multiChoice" :
        tmp = /* MultiChoice */{
          _0: Json_decode.field("metadata", (function (param) {
                  return decodeMetadata("MultiChoice", param);
                }), json)
        };
        break;
    case "shortText" :
        tmp = /* ShortText */2;
        break;
    default:
      Rollbar.error("Unkown kind: " + (otherKind + "received in CurriculumEditor__TargetChecklistItem"));
      tmp = /* LongText */3;
  }
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          kind: tmp,
          optional: Json_decode.field("optional", Json_decode.bool, json)
        };
}

function encodeMetadata(kind) {
  if (typeof kind === "number") {
    return Json_encode.object_(/* [] */0);
  } else {
    return Json_encode.object_({
                hd: [
                  "choices",
                  kind._0
                ],
                tl: /* [] */0
              });
  }
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "kind",
                kindAsString(t.kind)
              ],
              tl: {
                hd: [
                  "title",
                  t.title
                ],
                tl: {
                  hd: [
                    "optional",
                    t.optional
                  ],
                  tl: {
                    hd: [
                      "metadata",
                      encodeMetadata(t.kind)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function encodeChecklist(checklist) {
  return Json_encode.array(encode, checklist);
}

var longText = {
  title: "",
  kind: /* LongText */3,
  optional: false
};

export {
  t ,
  title ,
  kind ,
  optional ,
  actionStringForKind ,
  kindAsString ,
  make ,
  updateTitle ,
  updateKind ,
  updateOptional ,
  removeItem ,
  moveUp ,
  moveDown ,
  copy ,
  removeMultichoiceOption ,
  addMultichoiceOption ,
  updateMultichoiceOption ,
  longText ,
  isFilesKind ,
  isValidChecklistItem ,
  decodeMetadata ,
  decode ,
  encodeMetadata ,
  encode ,
  encodeChecklist ,
  
}
/* I18n Not a pure module */
